import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'OREO ALLSTAR CONTEST',
        'close': 'Close',

        //header

        //nav
        'menu.item.home' : 'HOME',
        'menu.item.winner' : 'PAST WINNERS',
        'menu.item.1': 'ENTER CONTEST',
        'menu.item.2': 'PRIZE DETAILS',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RULES',
        'menu.item.5': 'PRODUCTS',

        'contact-email': 'customercare@snackseatdrivers.ca',

        //footer
        'footer.link1' : 'Rules',
        'footer.link2' : 'Privacy Policy',
        'footer.link3' : 'Quebec Privacy Offer', 
        'footer.link4' : 'Terms of Use',
        'footer.link5' : 'Accessibility Policy',
        'footer.legal' : 'Sponsor: Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9 ©Mondelez International Group\nAll trademarks are property of their respective owners. ©NHL is not responsible for nor is a Sponsor of this promotion. ',

        'legal' : 'Open to residents of Ontario Only ',
        'legal.link' : '',

        //homapge
        'homepage.gameon' : 'you could',
        'homepage.unlock': '2024 NHL<small>®</small> all-star events',
        'homepage.prize1' : '<small>to </small>1<small> of </small>3',
        'homepage.prize' : 'win<small>*</small> tickets',
        'homepage.pluscash': 'With Instant Win*',
        'homepage.pluscash2': '',
        'homepage.pluscash3': '',
        'homepage.prepluscash': 'COMING SOON',
        'homepage.preprize': 'OREO ALLSTAR CONTEST',
        'homepage.cta' : ' ',
        'homepage.howtoenter' : '',
        'homepage.step1head' : '',
        'homepage.step1' : '',
        'homepage.step2head' : '',
        'homepage.step2' : '',
        'homepage.step3head' : '',
        'homepage.step3' : '',


        'contestClosedHead1' : '',
        'contestClosedHead' : 'CONTEST CLOSED',
        'contestClosedText' : 'Thanks for visiting!{br}Our contest is now closed!{br}{br}Congratulations to all our winners!',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',
        
        'enterCta' : 'ENTER',
        'enterNow' : 'ENTER',

         //coming soon and home page
        'comingsoon' : 'COMING SOON!',
        'comingsoon.desc' : "Be sure to return January 17th to enter the contest.",

        //enter contest page
        'step1': '',
        'enterPin': '',
        'enterKeyDescription': '',
        'enterKeyLink': '',
        'enterKeySmall': '',
        'popupBodytext' : '',
        'popupBodylink' : '',
        'pin': '',
        'nextStep': 'NEXT',

        'step2': 'Step 1',
        'enterInfo': 'ENTRY INFORMATION',
        'enterInfoDescription': 'Enter the contest for a chance to WIN* tickets',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'emailAddress': 'Email Address',
        'phone': 'Phone',
        'infoForm.age': 'I confirm that I am the age of majority and resident of Ontario*',
        'infoForm.rules' : 'I have read and confirm compliance with the ',
            'infoForm.rules.link1' : 'Contest rules and regulations',
            'infoForm.rules2' : ' and the ',
            'infoForm.rules.link2' : 'Mondelēz Privacy Policy*',
            'infoForm.rules3' : ' ',
        'infoForm.optin' : 'I confirm my consent to Mondelez Canada Inc. storing, sharing and using my personal information submitted for the purpose of administering the Contest (including notifying me if I am selected as an eligible winner of a prize) in accordance with the Contest Rules and Regulations and the Mondelez Privacy Policy*',
        'requiredFields' : '*Required fields',
        'submit': 'Submit',
        'homepage.legal': '*NO PURCHASE NECESSARY. Ends January 23, 2024 at 11:59:59 p.m. ET. 6 Prizes available at contest start: (i) 2 x 1 pair of tickets to 2024 Hyundai NHL Fan Fair™, (ARV $50), (ii) 2 x 1 pair of tickets to 2024 NHL All-Star Skills™, (ARV $600), (iii) 2 x 1 pair of tickets to 2024 Rogers NHL® All-Star Game (ARV $600). Open 18+, Ontario residents only, Contest draw date Jan. 24, 2024, correct answer to math skill test required, see contest rules for full details.',



        //winning page
        'congratulation' : '',
        'winningPage.heading' : '',
        'winningPage.body' : '',
        'winningPage.question' : '',
        'winningPage.answer' : "",
        'winningPage.cta' : 'SUBMIT',
        'winningPage.field1': '',
        'winningPage.field2': '',
        'winningPage.field3': '',
        'winningPage.field4': 'Province/Territory',
        'winningPage.field5': '',
        // 'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : '!',
        'winningPage.heading2' : '',
        'winningPage.body2' : '',
        'winningPage.legal' : '',

        'winningPage.heading3' : '',
        'winningPage.body3' : '',

        'winningPage.heading4' : '',
        'winningPage.body4': '',

        'address1': 'Address',
        'address2': 'Apartment/Suite',
        'city': 'City',
        'prov': 'Province/Territory',
        'postal': 'Postal Code',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Download',


        //losing page
        'tryAgain' : 'GOOD LUCK!',
        'tryAgain.body' : 'You\'ve been entered into the Contest\u00a0Draw.',
        'tryAgain.body2' : '*See <u>contest rules</u> for more prizing details. ',

        //Prize Details
        'prizeDetails' : '6 PRIZES AVAILABLE TO BE WON*',

        'prizeDetails-heading1' : '',
        'prizeDetails-subheading1' : '',
        'prizeDetails-prod1heading' : '2024 NHL All-Star Skills™ Tickets - 2 prizes available',
        'prizeDetails-prod2heading' : '2024 Rogers NHL® All-Star Game Tickets – 2 prizes available',
        'prizeDetails-prod3heading' : '2024 Hyundai NHL Fan Fair™ Tickets – 2 prizes available',
        // 'prizeDetails-prod4heading' : '$300 Gas Card',
        'prizeDetails-prod1' : "Each prize consists of 2 tickets per winner (ARV $600)",
        'prizeDetails-prod2' : "Each prize consists of 2 tickets per winner (ARV $600)",
        'prizeDetails-prod3' : "Each prize consists of 2 tickets per winner (ARV $50)",
       
        "prizeDetails-legal": '*See <u>contest rules</u> for more prizing details. ',



        //Rules page
        'rules-page-heading': 'CONTEST RULES',
    }
}