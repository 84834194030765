import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import ReactGA, { OutboundLink } from 'react-ga';
import { TextField, FormControlLabel, MenuItem } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

//language file
import { translate } from '../../i18n/translate';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 24,
    height: 26,
    
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 26,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
  });
  
  function BpCheckbox(props: CheckboxProps) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }


class InfoForm extends Component{

  state = {
    error: {},
    complete: false,
    prov: '',
    fName: '',
    lName: '',
    email: '',
    age: false,
    rules: false,
    optin: false,

  }

    //overlay css
    overlayCSS = {
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.01)',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99',
        display: 'none',
        transition: 'all .3s ease'
    }

    //overlay active css
    overlayActiveCSS = {
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.6)',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99',
        display: 'block',
        transition: 'all .3s ease'
    }

    componentDidMount(){

        window.scrollTo(0, 0);

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        // const form = this.form;

        // gsap.from(form, .6, {
        //     opacity: 0,
        //     x: 300,
        //     ease: Power3.easeOut
        // })

        //animating error message
        const {error} = this;
        if (error) {
            gsap.from(error, .6, {
                opacity: 0,
                y: 300,
                ease: Power3.easeOut
            })
        }
    }
  

    render(){
        // const { fName, lName, email, age, rules, optin, prov, error, complete } = this.state;
        const { handleChange, handleCheckbox, values, errorMessages, handleSubmitInfo } = this.props;
        const { invalidEntry, tooManyReqIp, tooManyReqEmail, internalError, dbError, badReq, captchaError, closeWarningfromInfoForm } = this.props;
        const { loading, lang } = this.props;

        return(
            <Fragment>
                <div className="">
                    <div className="container info-form__container">
                        {/* <p className="steps">{translate('step2')}</p>
                        <h1 className="form__heading pin-form__heading">{translate('enterInfo')}</h1> */}
                        <p className="form__description pin-form__description">{translate('enterInfoDescription')}</p>
                        
                        <div className="mui-container info-form" ref={el => { this.form = el}}>
                            <form onSubmit={handleSubmitInfo}>
                                <div className="row">
                                    <div className="col-12">
                                        <TextField className={errorMessages.fNameError ? 'error' : ''} variant="filled"  label={translate('firstName')} defaultValue={values.fName} onChange={handleChange('fName')} type="text" required helperText={errorMessages.fNameError} autoComplete="given-name" name="firstName" />
                                        <TextField className={errorMessages.lNameError ? 'error' : ''} variant="filled"  label={translate('lastName')} defaultValue={values.lName} onChange={handleChange('lName')} type="text" required helperText={errorMessages.lNameError} autoComplete="family-name" name="lastName" />
                                        <TextField className={errorMessages.emailError ? 'error' : ''} variant="filled"  label={translate('emailAddress')} defaultValue={values.email} onChange={handleChange('email')} type="email" required helperText={errorMessages.emailError} autoComplete="email" name="email" />
                                        <TextField className={errorMessages.provError ? 'error' : ''} variant="filled"  label={translate('winningPage.field4')} defaultValue={values.prov} onChange={handleChange('prov')} type="prov" select required helperText={errorMessages.provError} name="province"   >
                                          <MenuItem value={"Ontario"}>Ontario</MenuItem>
                                          <MenuItem value={'Alberta'}>Alberta</MenuItem>
                                          <MenuItem value={'British Columbia'}>British Columbia</MenuItem>
                                          <MenuItem value={'Manitoba'}>Manitoba</MenuItem>
                                          <MenuItem value={'New Brunswick'}>New Brunswick</MenuItem>
                                          <MenuItem value={'Newfoundland and Labrador'}>Newfoundland and Labrador</MenuItem>
                                          <MenuItem value={'Northwest Territories'}>Northwest Territories</MenuItem>
                                          <MenuItem value={'Nova Scotia'}>Nova Scotia</MenuItem>
                                          <MenuItem value={'Nunavut'}>Nunavut</MenuItem>
                                          <MenuItem value={'Prince Edward Island'}>Prince Edward Island</MenuItem>
                                          <MenuItem value={'Quebec'}>Quebec</MenuItem>
                                          <MenuItem value={'Saskatchewan'}>Saskatchewan</MenuItem>
                                          <MenuItem value={'Yukon'}>Yukon</MenuItem>
                                      </TextField>
                                        <p className="info-form__legal">{translate('requiredFields')}</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="checkbox-input">
                                            <FormControlLabel value={values.age} control={ <BpCheckbox required checked={values.age} disableRipple name="age" onClick={handleCheckbox} label="Age" style={{color: '#ffffff'}} /> } label="" labelPlacement="end" />
                                            <p className="checkbox-input__label">{translate('infoForm.age')}</p>
                                        </div>
                                        <p className="infoForm__errorMessage">{errorMessages.ageError}</p>

                                        <div className="checkbox-input">
                                            <FormControlLabel value={values.rules} control={ <BpCheckbox required checked={values.rules} disableRipple name="rules" onClick={handleCheckbox} label="Rules" style={{color: '#ffffff'}} /> } label="" labelPlacement="end" />
                                            <p className="checkbox-input__label">
                                                {translate('infoForm.rules')}
                                                <NavLink exact to='/rules' target="_blank">{translate('infoForm.rules.link1')}</NavLink>
                                                {translate('infoForm.rules2')}
                                                <OutboundLink eventLabel="Footer Privacy Policy"
                                                    to={lang === "fr-CA" ? "https://disclaimer.mondelezinternational.com/ca/privacy_policy_fr.aspx" : "https://disclaimer.mondelezinternational.com/ca/privacy_policy_en.aspx"}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    trackerNames={['tracker2']}>
                                                        {translate('infoForm.rules.link2')}
                                                </OutboundLink>
                                                {translate('infoForm.rules3')}
                                            </p>
                                        </div>
                                        <p className="infoForm__errorMessage">{errorMessages.rulesError}</p>

                                        <div className="checkbox-input">
                                        <FormControlLabel value={values.optin} control={ <BpCheckbox required checked={values.optin} disableRipple name="optin" onClick={handleCheckbox} label="Newsletter" style={{color: '#ffffff'}} /> } label="" labelPlacement="end" />
                                            
                                            <p className="checkbox-input__label">{translate('infoForm.optin')}</p>
                                        </div> 
                                        <p className="infoForm__errorMessage">{errorMessages.optinError}</p>
                                        <div className="checkbox-input">
                                            <FormControlLabel control={ <Checkbox required hidden disableRipple name="placehoder" onClick={handleCheckbox} label="Newsletter" style={{color: '#ffffff'}} tabIndex="0" /> } label="" labelPlacement="end" />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="pin-form__submit-btn enter-btn" onClick={handleSubmitInfo} >
                                  {translate('winningPage.cta')}
                                </button>
                            </form>
                        </div>

                    </div>

                    {invalidEntry && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Un ou plusieurs champs erroné(s).' : 'One or more entries are invalid'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {tooManyReqIp && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Trop de demandes de participation à partir de la même adresse IP.' : 'Too many requests from the same IP'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {tooManyReqEmail && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Vous avez atteint le nombre maximal de participations par jour. Veuillez réessayer demain.' : 'You\'ve reached the maximum number of entries per day. Please try again tomorrow.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {internalError && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Désolé. Une erreur s’est produite. Essayez à nouveau.' : 'Sorry, there was an error on our side. Please try again.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {dbError && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Le serveur est actuellement en maintenance. Essayez à nouveau.' : 'The server is currently undergoing maintenance. Please try again later.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {badReq && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Désolé. Une erreur s’est produite. Essayez à nouveau.' : 'Sorry, there was an error on our side. Please try again.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {captchaError && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Veuillez vérifier que vous n’êtes pas un robot.' : 'Please verify that you are not a robot.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}

                </div>
                <div className="api-calling-overlay" style={loading ? this.overlayActiveCSS : this.overlayCSS }></div>
            </Fragment>
        )
    }
}

export default InfoForm;