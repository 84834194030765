import React, { Component, Fragment } from 'react';
import { OutboundLink } from 'react-ga';
import { NavLink } from 'react-router-dom';

//language file
import { translate } from '../../i18n/translate';

//image import
// import Oreo from '../../images/oreo.png';
import MondelezLogo from '../../images/mondelez.png';

import './footer.scss';

class Footer extends Component{

    render(){
        return(
            <Fragment>
                <footer>
                    <div className="site-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="footer-links">
                                        <li>
                                            <NavLink exact to="/rules">{translate('footer.link1')}</NavLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Privacy Policy"
                                                to={this.props.lang === "fr-CA" ? "https://disclaimer.mondelezinternational.com/ca/privacy_policy_fr.aspx" : "https://disclaimer.mondelezinternational.com/ca/privacy_policy_en.aspx"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link2')}   
                                            </OutboundLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Quebec Privacy "
                                                to={this.props.lang === "fr-CA" ? "https://www.mondelezinternational.com/canada/quebec-privacy-officer/" : "https://www.mondelezinternational.com/canada/quebec-privacy-officer/"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link3')}  
                                            </OutboundLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Terms of Use"
                                                to={this.props.lang === "fr-CA" ? "https://www.mondelezinternational.com/terms-of-use/" : "https://www.mondelezinternational.com/terms-of-use/"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link4')}  
                                            </OutboundLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Accessibility Policy"
                                                to={this.props.lang === "fr-CA" ? "https://www.mondelezinternational.com/assets/Country/Canada/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf" : "https://www.mondelezinternational.com/assets/Country/Canada/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link5')}  
                                            </OutboundLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mondelez-logo">
                                <div className="col-md-12">
                                    <div className="mondelez-logo-container">
                                        <img src={MondelezLogo} alt="Mondelez Logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-10 offset-md-1 text-md-center footer-legal'>
                                    <p>{translate('footer.legal')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;